import { Box, Column, Columns } from '@nib/layout';
import NavContent, { NavContentProps } from '../../components/NavContent';
import React, { ReactNode, useEffect, useState } from 'react';
import ImportantInformationBanner, {
  ImportantInformationBannerProps,
} from '../../components/ImportantInformationBanner';
import ContentHeroPanel, { ContentHeroPanelProps } from '../../components/ContentHeroPanel';
import MobileNavbar from '../../components/MobileNavbar';
import styled from 'styled-components';
import ContentBanner, { ContentBannerProps } from '../../components/ContentBanner';
import useSalesforceForm from '../../hooks/useSalesforceForm';

const DESKTOP_XL = 960;

export interface LifeLivingInsuranceSectionProps {
  children?: ReactNode;
  heroContent?: ContentHeroPanelProps;
  navigation: NavContentProps;
  importantInformationData?: ImportantInformationBannerProps;
  bannerProps?: ContentBannerProps;
}

const Navigation = styled.nav`
  position: sticky;
  top: 0;
  padding-top: 1rem;
  padding-bottom: 5rem;
  z-index: 5;
`;

/**
 * Check if the code is running in a browser environment. This check is needed to prevent the
 * Gatsby build process from breaking with the following error:
 *
 * "window" is not available during Server-Side Rendering. Enable "DEV_SSR" to debug this during "gatsby develop".
 */
const isBrowser = () => typeof window !== 'undefined';

const LifeLivingInsuranceSection = ({
  children,
  heroContent,
  navigation,
  importantInformationData,
  bannerProps,
}: LifeLivingInsuranceSectionProps) => {
  useSalesforceForm();
  const [isMobile, setIsMobile] = useState(isBrowser() && window.innerWidth < DESKTOP_XL);

  useEffect(() => {
    if (!isBrowser()) return;

    const handleResize = () => {
      setIsMobile(window.innerWidth < DESKTOP_XL);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <ContentHeroPanel {...heroContent} />
      <Columns collapseBelow="xl" height="100%">
        <Column width="content" flex={true}>
          {!isMobile && (
            <Box margin={8} height="100%">
              <Navigation role="navigation" aria-label="Life and Living Insurance navigation">
                <NavContent {...navigation} />
              </Navigation>
            </Box>
          )}
        </Column>
        <Column>{children}</Column>
      </Columns>
      {isMobile && <MobileNavbar navigation={navigation} />}
      {bannerProps && (
        <Box paddingTop={8}>
          <ContentBanner {...bannerProps} />
        </Box>
      )}
      <ImportantInformationBanner {...importantInformationData} />
    </>
  );
};

export default LifeLivingInsuranceSection;
