import Heading from '@nib-components/heading';
import styled, { ThemeProvider } from 'styled-components';
import { Box } from '@nib/layout';
import React from 'react';
import {
  colorBrightGreen,
  colorSunsetPink80,
  colorTrueGreen,
  colorWhite,
} from '@nib-components/theme';
import Link from '@nib-components/link';
import { Bold } from '@nib-components/copy';
import Arrow from './arrow.svg';
import QuestionIcon from './question.svg';

export interface NavLinkProps {
  text: string;
  isActive?: boolean;
  href?: string;
}

export interface NavButtonProps {
  text: string;
  href?: string;
}

export interface NavContentProps {
  title: string;
  homeHref?: string;
  primaryButton?: NavButtonProps;
  secondaryButton?: NavButtonProps;
  navigation?: NavLinkProps[];
}

const NavButton = styled.a`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  border-radius: 100px;
  box-sizing: border-box;
  cursor: pointer;
  border: ${(props) => props.theme.border};
  border-color: ${(props) => props.theme.borderColor};

  img {
    width: 24px;
  }
`;

const NavLinkContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 12px 16px;
  background-color: ${(props) => (props.isActive ? colorSunsetPink80 : colorWhite)};
  border-radius: 40px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  width: fit-content;
`;

const NavLink = (props: NavLinkProps) => {
  const { text, isActive, href } = props;

  return (
    <NavLinkContainer isActive={isActive}>
      <Link href={href} underline={false}>
        <Bold weight={isActive ? 700 : 400}>{text}</Bold>
      </Link>
    </NavLinkContainer>
  );
};

const navButtonPrimaryTheme = {
  background: colorTrueGreen,
  color: colorBrightGreen,
  border: 'none',
  borderColor: 'transparent',
};

const navButtonSecondaryTheme = {
  background: colorWhite,
  color: colorTrueGreen,
  border: '1.5px solid',
  borderColor: colorTrueGreen,
};

const NavContent = (props: NavContentProps) => {
  const { title, homeHref, primaryButton, secondaryButton, navigation } = props;

  return (
    <Box display="flex" flexDirection="column" padding={0} gap={4} width="100%">
      <Box>
        <Heading size={4} color="trueGreen" component="h3">
          <Link href={homeHref} underline={false}>
            {title}
          </Link>
        </Heading>
      </Box>
      <Box display="flex" flexDirection="column" gap="2">
        <Box display="flex" flexDirection="column">
          {navigation?.map((link, index) => (
            <NavLink key={index} {...link} />
          ))}
        </Box>
        <Box display="flex" flexDirection="column" gap="2" paddingVertical={5}>
          <ThemeProvider theme={navButtonSecondaryTheme}>
            <NavButton href={secondaryButton?.href}>
              <Bold>{secondaryButton?.text}</Bold>
              <img src={QuestionIcon} alt="" />
            </NavButton>
          </ThemeProvider>
          <ThemeProvider theme={navButtonPrimaryTheme}>
            <NavButton href={primaryButton?.href}>
              <Bold>{primaryButton?.text}</Bold>
              <img src={Arrow} alt="" />
            </NavButton>
          </ThemeProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default NavContent;
