import Heading from '@nib-components/heading';
import { colorTrueGreen, colorWhite } from '@nib-components/theme';
import { Box } from '@nib/layout';
import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import ChevronWhite from './chevron-white.svg';
import ChevronGreen from './chevron-green.svg';
import Link from '@nib-components/link';
import NavContent, { NavContentProps } from '../NavContent';

interface MobileNavbarProps {
  navigation: NavContentProps;
}

const PositionWrapper = styled.nav`
  width: 100%;
  z-index: 100;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
  flex-direction: column-reverse;
`;

const MobileNavbarContainer = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  background-color: ${(props) => props.theme.background};
  max-height: ${(props) => props.theme.maxHeight};
  padding: ${(props) => props.theme.padding};
  border-radius: ${(props) => props.theme.borderRadius};
  box-sizing: border-box;
  box-shadow: 0px 2px 40px 0px #00000050;
  transition: all 300ms ease-out;
  justify-content: center;
`;

const closedTheme = {
  background: colorTrueGreen,
  padding: '20px 24px',
  borderRadius: '100px',
  maxHeight: '60px',
};

const openTheme = {
  background: colorWhite,
  padding: '24px 24px',
  borderRadius: '16px',
  maxHeight: '600px',
};

const MobileNavbar = (props: MobileNavbarProps) => {
  const { navigation } = props;
  const [isClosed, setIsClosed] = useState(true);
  const [theme, setTheme] = useState(closedTheme);

  const handleToggle = () => {
    setTheme(isClosed ? openTheme : closedTheme);
    setIsClosed(!isClosed);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ' ' || event.key === 'Escape') {
      event.preventDefault();
      handleToggle();
    }
  };

  return (
    <PositionWrapper role="navigation" aria-label="Mobile Navigation">
      <ThemeProvider theme={theme}>
        <MobileNavbarContainer>
          {!isClosed && (
            <Box display="flex" flexDirection="column" padding={0} gap={4} width="100%">
              <NavContent {...navigation} />
              <Box
                display="flex"
                justifyContent="center"
                gap={2}
                textAlign="center"
                width="100%"
                onClick={handleToggle}
                onKeyDown={handleKeyDown}
                role="button"
                name="close navigation"
                aria-label="Close navigation"
                tabIndex={0}
                data-testid="mobile-navbar-opened-button"
              >
                <Link underline={false}>Close</Link>
                <Box display="flex" alignItems="center">
                  <img src={ChevronGreen} alt="Toggle" />
                </Box>
              </Box>
            </Box>
          )}
          {isClosed && (
            <Box
              display="flex"
              onClick={handleToggle}
              onKeyDown={handleKeyDown}
              role="button"
              aria-expanded={!isClosed}
              aria-controls="mobile-navbar"
              tabIndex={0}
              data-testid="mobile-navbar-closed-button"
            >
              <Box display="flex" alignItems="center">
                <Heading size={5} color="clearWhite" component="h3">
                  {navigation.navigation?.map((navigationItem) => {
                    if (navigationItem.isActive) {
                      return navigationItem.text;
                    }
                    return null;
                  })}
                </Heading>
              </Box>
              <Box display="flex" alignItems="center">
                <img src={ChevronWhite} alt="Toggle" />
              </Box>
            </Box>
          )}
        </MobileNavbarContainer>
      </ThemeProvider>
    </PositionWrapper>
  );
};

export default MobileNavbar;
